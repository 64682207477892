var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-sm-12"},[_c('h5',{staticClass:"info-text"},[_vm._v("Are you living in a nice area?")])]),_c('div',{staticClass:"col-sm-7"},[_c('ValidationProvider',{attrs:{"name":"street","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"label":"Street Name","error":errors[0]},model:{value:(_vm.street),callback:function ($$v) {_vm.street=$$v},expression:"street"}})]}}])})],1),_c('div',{staticClass:"col-sm-3"},[_c('ValidationProvider',{attrs:{"name":"streetNo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"label":"Street No","error":errors[0]},model:{value:(_vm.streetNo),callback:function ($$v) {_vm.streetNo=$$v},expression:"streetNo"}})]}}])})],1),_c('div',{staticClass:"col-sm-5"},[_c('ValidationProvider',{attrs:{"name":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"label":"City","error":errors[0]},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})]}}])})],1),_c('div',{staticClass:"col-sm-5"},[_c('label',[_vm._v("Country")]),_c('ValidationProvider',{attrs:{"name":"country","rules":"required|min:5"}},[_c('base-input',[_c('el-select',{staticClass:"select-primary",attrs:{"name":"country"},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}},_vm._l((_vm.countryOptions),function(country){return _c('el-option',{key:country,staticClass:"select-primary",attrs:{"label":country,"value":country}})}),1)],1)],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }