var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-shopping"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.productsTable}},[_c('el-table-column',{attrs:{"min-width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"img-container"},[_c('img',{attrs:{"src":row.image,"alt":"product image"}})])}}])}),_c('el-table-column',{attrs:{"min-width":"270","label":"Product"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"td-name"},[_c('a',{attrs:{"href":"#jacket"}},[_vm._v(_vm._s(row.title))]),_vm._v(" "),_c('br'),_c('small',[_vm._v(_vm._s(row.description))])])}}])}),_c('el-table-column',{attrs:{"min-width":"120","label":"Color","prop":"color"}}),_c('el-table-column',{attrs:{"min-width":"100","label":"Size","prop":"size"}}),_c('el-table-column',{attrs:{"min-width":"100","label":"Price","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('small',[_vm._v("€")]),_vm._v(" "+_vm._s(row.price)+" ")]}}])}),_c('el-table-column',{attrs:{"min-width":"160","label":"QTY","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"btn-group"},[_c('base-button',{staticClass:"btn-simple",attrs:{"type":"info","size":"sm"},on:{"click":function($event){return _vm.decreaseQuantity(row)}}},[_c('i',{staticClass:"tim-icons icon-simple-delete"})]),_c('base-button',{attrs:{"type":"info","size":"sm"},on:{"click":function($event){return _vm.increaseQuantity(row)}}},[_c('i',{staticClass:"tim-icons icon-simple-add"})])],1),_vm._v(" "+_vm._s(row.quantity)+" ")]}}])}),_c('el-table-column',{attrs:{"min-width":"100","label":"Amount","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('small',[_vm._v("€")]),_vm._v(" "+_vm._s(row.amount)+" ")]}}])}),_c('el-table-column',{attrs:{"min-width":"60","label":"","align":"left"}},[[_c('base-button',{staticClass:"btn-link",attrs:{"type":"primary"}},[_c('i',{staticClass:"tim-icons icon-simple-remove"})])]],2),_c('div',{staticClass:"stats-container",attrs:{"slot":"append"},slot:"append"},[_c('div',{staticClass:"stats-total"},[_c('div',{staticClass:"stats-total-numbers"},[_c('div',{staticClass:"td-total"},[_vm._v("Total")]),_c('div',{staticClass:"td-price mr-2"},[_c('small',[_vm._v("€")]),_vm._v(" "+_vm._s(_vm.shoppingTotal)+" ")])])]),_c('div',{staticClass:"d-flex justify-content-end"},[_c('base-button',{staticClass:"float-right",attrs:{"type":"info","round":"","title":""}},[_vm._v(" Complete Purchase "),_c('i',{staticClass:"tim-icons icon-minimal-right"})])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }