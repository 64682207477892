var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-7 mr-auto"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('card',{staticClass:"card-register card-white"},[_c('template',{slot:"header"},[_c('img',{staticClass:"card-img",attrs:{"src":"img/card-primary.png","alt":"Card image"}}),_c('h4',{staticClass:"card-title"},[_vm._v("Register")])]),_c('ValidationProvider',{attrs:{"name":"fullname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","placeholder":"Full Name","addon-left-icon":"tim-icons icon-single-02","type":"text","error":errors[0]},model:{value:(_vm.fullname),callback:function ($$v) {_vm.fullname=$$v},expression:"fullname"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","placeholder":"Email","addon-left-icon":"tim-icons icon-email-85","type":"email","error":errors[0]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","placeholder":"Password","addon-left-icon":"tim-icons icon-lock-circle","type":"password","error":errors[0]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('base-checkbox',{staticClass:"text-left"},[_vm._v(" I agree to the "),_c('a',{attrs:{"href":"#something"}},[_vm._v("terms and conditions")]),_vm._v(". ")]),_c('base-button',{attrs:{"slot":"footer","native-type":"submit","type":"primary","round":"","block":"","size":"lg"},slot:"footer"},[_vm._v(" Get Started ")])],2)],1)]}}])})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-5 ml-auto"},[_c('div',{staticClass:"info-area info-horizontal mt-5"},[_c('div',{staticClass:"icon icon-warning"},[_c('i',{staticClass:"tim-icons icon-wifi"})]),_c('div',{staticClass:"description"},[_c('h3',{staticClass:"info-title"},[_vm._v("Marketing")]),_c('p',{staticClass:"description"},[_vm._v(" We've created the marketing campaign of the website. It was a very interesting collaboration. ")])])]),_c('div',{staticClass:"info-area info-horizontal"},[_c('div',{staticClass:"icon icon-primary"},[_c('i',{staticClass:"tim-icons icon-triangle-right-17"})]),_c('div',{staticClass:"description"},[_c('h3',{staticClass:"info-title"},[_vm._v("Fully Coded in HTML5")]),_c('p',{staticClass:"description"},[_vm._v(" We've developed the website with HTML5 and CSS3. The client has access to the code using GitHub. ")])])]),_c('div',{staticClass:"info-area info-horizontal"},[_c('div',{staticClass:"icon icon-info"},[_c('i',{staticClass:"tim-icons icon-trophy"})]),_c('div',{staticClass:"description"},[_c('h3',{staticClass:"info-title"},[_vm._v("Built Audience")]),_c('p',{staticClass:"description"},[_vm._v(" There is also a Fully Customizable CMS Admin Dashboard for this product. ")])])])])}]

export { render, staticRenderFns }